import { takeLatest, put, call } from 'redux-saga/effects';
import { ManageApplicantsActionTypes } from '../actions/manageApplicantsActionTypes';
import {
  getApplicantsRequestSuccess,
  getApplicantsRequestError,
  updateApplicantsRequestSuccess,
  updateApplicantsRequestError
} from '../actions/manageApplicantsActions';
import { apiService } from '../../../services/api';

function* getApplicantsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getApplicants, req.payload);

    // Dispatch the success action with the API response
    yield put(getApplicantsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getApplicantsRequestError(error.message));
  }
}

function* updateApplicantsRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageApplicants, req.payload);

    // Dispatch the success action with the API response
    yield put(updateApplicantsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateApplicantsRequestError(error.message));
  }
}

export function* watchManageApplicantsRequest() {
  yield takeLatest(ManageApplicantsActionTypes.GET_APPLICANTS, getApplicantsRequestSaga);
  yield takeLatest(ManageApplicantsActionTypes.UPDATE_APPLICANTS, updateApplicantsRequestSaga);
}
