import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {
  Button,
  ButtonProps,
  Container,
  styled,
  Select,
  MenuItem,
  TextField,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  InputLabel,
  Input,
  FormControl,
  FormHelperText
} from '@mui/material';
import { EditCalendarRounded } from '@mui/icons-material';
import { orange } from '@mui/material/colors';
import DialogTitleWithClose from '../../../Components/DialogTitleWithClose';

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: orange[700],
    color: theme.palette.common.white
  }
}));

const ScheduleBar = ({ onAddSchedule, addOverlapError, setaddOverlapError }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [day, setDay] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const handleAddSchedule = () => {
    if (day && startTime && endTime) {
      let error = onAddSchedule({ day, startTime, endTime });
      if (!error) {
        setDay('');
        setStartTime('');
        setEndTime('');
        setShowDialog(false);
        resetForm();
      } else {
        setaddOverlapError(error);
      }
    }
  };

  const resetForm = () => {
    setaddOverlapError('');
    setDay('');
    setStartTime('');
    setEndTime('');
  };

  return (
    <AppBar position="static" sx={{ padding: '10px' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Available Time
          </Typography>
          <Button variant="contained" startIcon={<EditCalendarRounded />} onClick={() => setShowDialog(true)}>
            Add New Schedule
          </Button>
        </Toolbar>
      </Container>

      {/* Dialog for adding new schedule */}
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>

        <DialogTitleWithClose title={'Add New Schedule'} handleClose={() => setShowDialog(false)} />
        <DialogContent>
          <Box
            component="form"
            sx={{
              display: 'flex',
              '& > :not(style)': { m: 1, width: '25ch' },
              flexDirection: 'row',
              gap: 2
            }}
          >
            <FormControl>
              <Select
                id="availabilityDay"
                value={day}
                onChange={(e) => setDay(e.target.value)}
                displayEmpty
                sx={{ minWidth: 180 }}
              >
                <MenuItem value="" disabled>
                  Day of the Week
                </MenuItem>
                {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((d) => (
                  <MenuItem key={d} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="availabilityDay">Day of the Week</FormHelperText>
            </FormControl>
            <FormControl>
              <TextField
                id="startTime"
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                sx={{ minWidth: 180 }}
              />
              <FormHelperText id="startTime">Start Time</FormHelperText>
            </FormControl>
            <FormControl>
              <TextField
                id="endTime"
                type="time"
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                sx={{ minWidth: 180, pr: 2 }}
              />
              <FormHelperText id="endTime">End Time</FormHelperText>
            </FormControl>
          </Box>
          {addOverlapError && <p style={{ color: 'red' }}>{addOverlapError}</p>}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              resetForm();
              setShowDialog(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <ColorButton onClick={handleAddSchedule} color="primary">
            Add
          </ColorButton>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default ScheduleBar;
