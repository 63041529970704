// actions/homeworkActions.ts
import { InvoicesAction, InvoicesActionTypes } from "./invoiceActionTypes";

export const getInvoiceRequest = (data: any): InvoicesAction => ({
  type: InvoicesActionTypes.GET_INVOICES,
  payload: data
});


export const createInvoiceRequest = (data: any): InvoicesAction => ({
  type: InvoicesActionTypes.CREATE_INVOICES,
  payload: data
});

export const createInvoiceRequestSuccess = (data: any): InvoicesAction => ({
  type: InvoicesActionTypes.CREATE_INVOICES_SUCCESS,
  payload: data
});

export const createInvoiceRequestError = (error: string): InvoicesAction => ({
  type: InvoicesActionTypes.CREATE_INVOICES_ERROR,
  payload: error
});

export const updateInvoiceRequest = (data: any): InvoicesAction => ({
  type: InvoicesActionTypes.UPDATE_INVOICES,
  payload: data
});

export const getInvoiceRequestSuccess = (data: any): InvoicesAction => ({
  type: InvoicesActionTypes.GET_INVOICES_SUCCESS,
  payload: data
});

export const updateInvoiceRequestSuccess = (data: any): InvoicesAction => ({
  type: InvoicesActionTypes.UPDATE_INVOICES_SUCCESS,
  payload: data
});

export const getInvoiceRequestError = (error: string): InvoicesAction => ({
  type: InvoicesActionTypes.GET_INVOICES_ERROR,
  payload: error
});

export const updateInvoiceRequestError = (error: string): InvoicesAction => ({
  type: InvoicesActionTypes.UPDATE_INVOICES_ERROR,
  payload: error
});
