import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppState, AppDispatch } from "../../../store/configureStore";
import { bookings } from "../../../features/bookings/actions/bookingsActions";
import { Gen3Buttons } from "../../../globalStyles";

export default function ManageBookings() {
  const dispatch: AppDispatch = useDispatch();
  const user_api = useSelector((state: AppState) => state.login.account.userAPI);
  const idToken = useSelector((state: AppState) => state.login.user.idToken);
  const user_id = useSelector((state: AppState) => state.login.user.sub);
  const manageBookings = useSelector((state: AppState) => state.bookLocationReducer.data);

  useEffect(() => {
    dispatch(
      bookings({
        action: "get all",
        idToken: idToken,
        user_api: user_api,
        user_id,
      })
    );
  }, [dispatch, idToken, user_api, user_id]);

  console.log(manageBookings);

  const groupedBookings = manageBookings?.data?.Items?.reduce((acc: any, item: any) => {
    if (!acc[item.resource_type]) {
      acc[item.resource_type] = [];
    }
    acc[item.resource_type].push(item);
    return acc;
  }, {});

  const formatTitle = (title: string) => {
    return title
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', p: 2 }}>
      <Stack
       direction="row" justifyContent="space-between" alignItems="center" style={{ marginTop: '20px',marginBottom:"10px" }}>
        <Typography variant="h4" fontWeight="bold" color={'chocolate'}>
          Bookings
        </Typography>
      </Stack>
    <Divider/>
    <br/>
      {groupedBookings &&
        Object.entries(groupedBookings).map(([resourceType, bookings]: [string, any[]]) => (
          <Accordion defaultExpanded key={resourceType}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: "#f5f5f5",
                borderBottom: "1px solid #ddd",
                "&:hover": {
                  backgroundColor: "#e0e0e0",
                },
              }}
            >
               <Typography variant="h4" fontWeight="bold" color={'chocolate'}>
                {formatTitle(resourceType)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{textAlign:"center"}}>Booking ID</TableCell>
                    <TableCell style={{textAlign:"center"}}>Booking Date</TableCell>
                    <TableCell style={{textAlign:"center"}}>Cost</TableCell>
                    <TableCell style={{textAlign:"center"}}>Status</TableCell>
                    <TableCell style={{textAlign:"center"}}>Contact Name</TableCell>
                    <TableCell style={{textAlign:"center"}}>Contact Phone</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookings.map((booking: any) => (
                    <TableRow key={booking.booking_id}>
                      <TableCell style={{textAlign:"center"}}>{booking.booking_id}</TableCell>
                      <TableCell style={{textAlign:"center"}}>{booking.booking_date}</TableCell>
                      <TableCell style={{textAlign:"center"}}>{booking.cost || 0}</TableCell>
                      <TableCell style={{textAlign:"center"}}>{booking.status}</TableCell>
                      <TableCell style={{textAlign:"center"}}>{booking.booking_contact_name || "None"}</TableCell>
                      <TableCell style={{textAlign:"center"}}>{booking.booking_contact_phone || "None"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
  </Box>
  );
}
