import React, { useState, useEffect, useCallback } from 'react';
import axios from '../../Utils/axios';

import SEO from '../../Components/seo';
import PageHeader from './PageHeader';
import FooterContainer from '../../Components/Footer/FooterContainer';
import Statistics from './Statistics';
import PageTitleWrapper from '../LayoutPage/PageTitleWrapper';

import { Grid } from '@mui/material';
import useRefMounted from '../../hooks/useRefMounted';

import Results from './Results';
import { AppState } from '../../store/configureStore';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceRequest } from '../../features/Invoices/actions/invoiceActions';

function Invoices() {
  const isMountedRef = useRefMounted();

  const idToken: any = useSelector((state: AppState) => state.login.user.idToken);
  const user_api: any = useSelector((state: AppState) => state.login.account.userAPI);
  const invoice_Data: any = useSelector((state: AppState) => state.invoiceReducer.data);

  const user_id = localStorage.getItem('userId');

  const dispatch: AppState = useDispatch();

  const generateDummyInvoices = () => {
    return Array.from({ length: 5 }, (_, index) => ({
      id: index + 1,
      amount: (Math.random() * 1000).toFixed(2),
      date: new Date().toISOString().split('T')[0],
      status: index % 2 === 0 ? 'Paid' : 'Pending'
    }));
  };

  const getInvoicesData = useCallback(async () => {
    try {
      const payload = {
        user_id,
        user_api,
        idToken
      };
      dispatch(getInvoiceRequest(payload));
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getInvoicesData();
  }, []);


  console.log("invoices")
  console.log(invoice_Data)

  return (
    <React.Fragment>
      <SEO title="Invoices - Management" />
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>

      <Grid
        sx={{
          px: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12}>
          <Statistics />
        </Grid>
        <Grid item xs={12}>
          <Results invoices={invoice_Data?.data ?? []} />
        </Grid>
      </Grid>
      <FooterContainer />
    </React.Fragment>
  );
}

export default Invoices;
