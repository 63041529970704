import { CareersApplyActionTypes, CareersApplyAction } from '../actions/careersApplyActionTypes';

interface CareersApplyState {
  data: string[];
  loading: boolean;
  error: string | null;
  updating: boolean;
  updated_data: string[];
  deleting: boolean;
  deleted_data: string[];
  message: string | null;
}

const initialState: CareersApplyState = {
  data: [],
  loading: false,
  error: null,
  updating: false,
  updated_data: [],
  deleting: false,
  deleted_data: [],
  message: null
};

const careersApplyReducer = (state = initialState, action: CareersApplyAction): CareersApplyState => {
  switch (action.type) {
    case CareersApplyActionTypes.CAREERS_APPLY_REQUEST:
      return { ...state, loading: true };
    case CareersApplyActionTypes.CAREERS_APPLY_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, error: null };
    case CareersApplyActionTypes.CAREERS_APPLY_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case CareersApplyActionTypes.CAREERS_APPLY_UPDATE_REQUEST:
      return { ...state, updating: true };
    case CareersApplyActionTypes.CAREERS_APPLY_UPDATE_REQUEST_SUCCESS:
      return { ...state, updated_data: action.payload, updating: false, error: null };
    case CareersApplyActionTypes.CAREERS_APPLY_UPDATE_REQUEST_ERROR:
      return { ...state, updating: false, error: action.payload };

    case CareersApplyActionTypes.CAREERS_APPLY_DELETE_REQUEST:
      return { ...state, deleting: true };
    case CareersApplyActionTypes.CAREERS_APPLY_DELETE_REQUEST_SUCCESS:
      return { ...state, deleted_data: action.payload, deleting: false, error: null };
    case CareersApplyActionTypes.CAREERS_APPLY_DELETE_REQUEST_ERROR:
      return { ...state, deleting: false, error: action.payload };

    case CareersApplyActionTypes.CAREERS_APPLY_MESSAGE_REQUEST:
      return { ...state, message: null };
    default:
      return state;
  }
};

export default careersApplyReducer;
