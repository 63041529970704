// AuthContext.js

import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import loginReducer from '../features/login/reducers/loginReducer';
import { LoginAction } from '../features/login/actions/LoginActionTypes';
import { initialState, LoginState } from '../models/login';

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<
  | {
      state: LoginState;
      dispatch: React.Dispatch<LoginAction>;
    }
  | undefined
>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(loginReducer, initialState);

  const contextValue = {
    state,
    dispatch,
    AuthContext
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const justUseAuth = () => {
  const useAuth = () => useContext(AuthContext);
  return useAuth;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
