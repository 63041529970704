import { takeLatest, put, call } from 'redux-saga/effects';
import { ScheduleActionTypes } from '../actions/getScheduleActionTypes';
import {
  getScheduleRequestSuccess,
  getScheduleRequestError,
  updateScheduleRequestSuccess,
  updateScheduleRequestError,
  deleteScheduleRequestSuccess,
  deleteScheduleRequestError,
  getRescheduleRequestSuccess,
  getRescheduleRequestError,
  updateteacherScheduleRequestSuccess,
  updateTeacherScheduleRequestError,
  getPastScheduleRequestError,
  getPastScheduleRequestSuccess
} from '../actions/getScheduleActions';
import { apiService } from '../../../services/api';

function* getScheduleRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getSchedule, req.payload);

    // Dispatch the success action with the API response
    yield put(getScheduleRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getScheduleRequestError(error.message));
  }
}

export function* getRescheduleRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.requestCourseReschedule, req.payload);
    // Dispatch the success action with the API response
    yield put(getRescheduleRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getRescheduleRequestError(error.message));
  }
}

function* updateScheduleRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getSchedule, req.payload);

    // Dispatch the success action with the API response
    yield put(updateScheduleRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateScheduleRequestError(error.message));
  }
}

function* getRescheduleAndScheduleRequestSaga(req: any) {
  try {
    const rescheduleResponse = yield call(apiService.requestCourseReschedule, req.payload);
    yield put(getRescheduleRequestSuccess(rescheduleResponse));
    if (req.payload.refetchSchedule) {
      const scheduleResponse = yield call(apiService.getSchedule, req.payload);
      yield put(getScheduleRequestSuccess(scheduleResponse));
    }
  } catch (error: any) {
    yield put(getRescheduleRequestError(error.message));
    yield put(getScheduleRequestError(error.message));
  }
}

function* deleteScheduleRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getSchedule, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteScheduleRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteScheduleRequestError(error.message));
  }
}

function* getPastScheduleRequestSaga(req) {
  try {
    const response = yield call(apiService.getSchedule, req.payload);
    yield put(getPastScheduleRequestSuccess(response));
  } catch (error: any) {
    yield put(getPastScheduleRequestError(error.message));
  }
}

function* getSpecificTeacherReschduleSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.getSchedule, req.payload);

    // Dispatch the success action with the API response
    yield put(updateteacherScheduleRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateTeacherScheduleRequestError(error.message));
  }
}

export function* watchScheduleRequest() {
  // Watch for separate requests
  yield takeLatest(ScheduleActionTypes.GET_SCHEDULE_REQUEST, getScheduleRequestSaga);
  yield takeLatest(ScheduleActionTypes.GET_RESCHEDULE_REQUEST, getRescheduleRequestSaga);

  // Watch for combined reschedule and schedule requests
  yield takeLatest(ScheduleActionTypes.GET_RESCHEDULE_AND_SCHEDULE_REQUEST, getRescheduleAndScheduleRequestSaga);

  // Existing individual watchers
  yield takeLatest(ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST, updateScheduleRequestSaga);
  yield takeLatest(ScheduleActionTypes.DELETE_SCHEDULE_REQUEST, deleteScheduleRequestSaga);
  yield takeLatest(ScheduleActionTypes.GET_Teacher_Reschdule_REQUEST, getSpecificTeacherReschduleSaga);
  yield takeLatest(ScheduleActionTypes.GET_PAST_SCHEDULE_REQUEST, getPastScheduleRequestSaga);
}
