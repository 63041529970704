import { ManageApplicantsActionTypes, ManageApplicantsAction } from '../actions/manageApplicantsActionTypes';

interface ManageApplicantsState {
  data: string[];
  getting: boolean;
  get_error: string | null;
  update_data: string[];
  updating: boolean;
  update_error: string | null;
}

const initialState: ManageApplicantsState = {
  data: [],
  getting: false,
  get_error: null,
  update_data: [],
  updating: false,
  update_error: null
};

const manageApplicantsReducer = (state = initialState, action: ManageApplicantsAction): ManageApplicantsState => {
  switch (action.type) {
    case ManageApplicantsActionTypes.GET_APPLICANTS:
      return { ...state, getting: true };
    case ManageApplicantsActionTypes.GET_APPLICANTS_SUCCESS:
      return { ...state, data: action.payload, getting: false, get_error: null };
    case ManageApplicantsActionTypes.GET_APPLICANTS_ERROR:
      return { ...state, getting: false, get_error: action.payload };
    case ManageApplicantsActionTypes.UPDATE_APPLICANTS:
      return { ...state, updating: true };
    case ManageApplicantsActionTypes.UPDATE_APPLICANTS_SUCCESS:
      return { ...state, update_data: action.payload, updating: false, update_error: null };
    case ManageApplicantsActionTypes.UPDATE_APPLICANTS_ERROR:
      return { ...state, updating: false, update_error: action.payload };
    default:
      return state;
  }
};

export default manageApplicantsReducer;
