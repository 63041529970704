import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { getInvoiceRequestError, getInvoiceRequestSuccess, updateInvoiceRequestError, updateInvoiceRequestSuccess, createInvoiceRequestError, createInvoiceRequestSuccess } from '../actions/invoiceActions';
import { InvoicesActionTypes } from '../actions/invoiceActionTypes';

function* getInvoices(req) {
  try {
    const response = yield call(apiService.getInvoices, req.payload);
    yield put(getInvoiceRequestSuccess(response));
  } catch (error: any) {
    yield put(getInvoiceRequestError(error.message));
  }
}

function* createInvoice(req) {
  try {
    const response = yield call(apiService.createInvoice, req.payload);
    yield put(createInvoiceRequestSuccess(response));
  } catch (error: any) {
    yield put(createInvoiceRequestError(error.message));
  }
}

function* updateInvoice(req) {
  try {
    const response = yield call(apiService.updateInvoice, req.payload);
    yield put(updateInvoiceRequestSuccess(response));
  } catch (error: any) {
    yield put(updateInvoiceRequestError(error.message));
  }
}

export function* watchInvoiceRequest() {
  yield takeLatest(InvoicesActionTypes.GET_INVOICES, getInvoices);
  yield takeLatest(InvoicesActionTypes.UPDATE_INVOICES, updateInvoice);
  yield takeLatest(InvoicesActionTypes.CREATE_INVOICES, createInvoice);
}
