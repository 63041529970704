import { ca } from "date-fns/locale";
import { InvoicesActionTypes } from "../actions/invoiceActionTypes";


const initialState = {
  data: null,
  createData: null,
  createLoading: false,
  createError: null,
  loading: false,
  error: null
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case InvoicesActionTypes.GET_INVOICES:
      return {
        ...state,
        loading: true
      };
    case InvoicesActionTypes.GET_INVOICES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case InvoicesActionTypes.GET_INVOICES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case InvoicesActionTypes.CREATE_INVOICES:
      return {
        ...state,
        createLoading: true
      };
    case InvoicesActionTypes.CREATE_INVOICES_SUCCESS:
      return {
        ...state,
        createLoading: false,
        createData: action.payload
      };
    case InvoicesActionTypes.CREATE_INVOICES_ERROR:
      return {
        ...state,
        createLoading: false,
        createError: action.payload
      };
    case InvoicesActionTypes.UPDATE_INVOICES:
      return {
        ...state,
        loading: true
      };
    case InvoicesActionTypes.UPDATE_INVOICES_SUCCESS:
      const updatedData = state?.data?.data?.map((invoice: any) => {
        console.log("Current invoice:", invoice);
        console.log("Updated item:", action.payload.updated_item);

        if (invoice.invoice_id === action.payload.updated_item?.invoice_id) {
          console.log("Match found, updating invoice.");
          return {
            ...invoice,
            ...action.payload.updated_item
          };
        }
        return invoice;
      });

      return {
        ...state,
        data: {
          ...state.data,
          data: updatedData
        },
        loading: false,
      };
    case InvoicesActionTypes.UPDATE_INVOICES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default invoiceReducer;
