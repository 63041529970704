import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AppState, AppDispatch } from "../../../store/configureStore";
import { careersApplyRequest } from "../../../features/careersApply/actions/careersApplyActions";

export default function ManageCareersApplications() {
  const dispatch: AppDispatch = useDispatch();
  const user_api = useSelector((state: AppState) => state.login.account.userAPI);
  const idToken = useSelector((state: AppState) => state.login.user.idToken);
  const user_id = useSelector((state: AppState) => state.login.user.sub);
  const careersApplicationData = useSelector((state: AppState) => state.careersApplyReducer.data);

  useEffect(() => {
    dispatch(
      careersApplyRequest({
        action: "get",
        idToken: idToken,
        user_api: user_api,
        user_id,
      })
    );
  }, [dispatch, idToken, user_api, user_id]);

  const applications = careersApplicationData?.applications || [];

  return (
    <Box sx={{ maxWidth: "100%", mx: "auto", p: 2,px:4 }}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: "#f5f5f5",
            borderBottom: "1px solid #ddd",
            "&:hover": {
              backgroundColor: "#e0e0e0",
            },
          }}
        >
          <Typography variant="h4" fontWeight="bold" color={'chocolate'}>
            Career Applications
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Status</TableCell>
                {applications.some((app: any) => app.reject_reason) && (
                  <TableCell>Rejection Reason</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.map((application: any) => (
                <TableRow key={application.job_id}>
                  <TableCell>{application.first_name}</TableCell>
                  <TableCell>{application.last_name}</TableCell>
                  <TableCell>{application.email_id}</TableCell>
                  <TableCell>{application.phone_number}</TableCell>
                  <TableCell>{application.city}</TableCell>
                  <TableCell>{application.status}</TableCell>
                    <TableCell>{application.reject_reason || "None"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
