import patternedBackground from './Assets/backgroundImg.svg';
import background_image from './Assets/CommunityLearningCenterNew.png';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { styled, GlobalStyles as GS } from '@mui/system';
import { Button, Tabs, Link as muiLink, ButtonProps, Typography } from '@mui/material';
import { Email } from '@mui/icons-material';
import Text from './Components/Text';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import {
  blackColor,
  primaryColor,
  secondaryColor,
  tertiaryColor,
  whiteColor,
  gen3SectionColor,
  gen3SectionTextColor,
  gen3AlternateSectionColor,
  gen3AlternateSectionTextColor,
  gen3SecondaryButtonHoverColor,
  gen3PrimaryButtonHoverColor,
  gen3BannerStripeColor,
  gen3BannerStripeTextColor
} from './styleVariables';

// Define the types for your styled component props
interface StyleProps {
  action?: boolean;
  big?: boolean;
  fontbig?: boolean;
  lightbg?: boolean;
  imgstart?: boolean;
  $start?: boolean;
  lightTopLine?: boolean;
  lightText?: boolean;
  lightTextDesc?: boolean;
  click?: boolean;
  marginBottom?: boolean;
  buttontype?: boolean;
  layout?: 'row-reverse';
  layout2?: 'row' | 'column';
  fullwidth?: boolean;
  buttonIcon?: boolean;
  emailIcon?: boolean;
}

interface Gen3ButtonProps {
  action?: boolean;
  fullwidth?: boolean;
  fontbig?: boolean;
  buttontype?: boolean;
  activeButton?: boolean;
}

const GlobalStyles = (
  <GS
    styles={{
      '*': {
        padding: 0,
        margin: 0,
        boxSizing: 'border-box'
      },
      '*::before': {
        boxSizing: 'border-box'
      },
      '*::after': {
        boxSizing: 'border-box'
      },
      '*::scrollbar': {
        scrollbarWidth: 'thin',
        scrollbarColor: 'rgba(155, 155, 155, 0.5) transparent'
      },
      '*::-webkit-scrollbar': {
        width: '0.5px'
      },
      '*::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      '*::-webkit-scrollbar-thumb': {
        background: 'rgba(155, 155, 155, 0.5)',
        borderRadius: '20px',
        border: 'transparent'
      },
      body: {
        margin: 0,
        fontFamily: 'Poppins, sans-serif',
        height: '100%',
        width: '100%'
      }
    }}
  />
);

export const FormPageContainer = styled('div')(({ theme }) => ({
  marginTop: '50px',
  paddingTop: '40px',
  width: '90%',
  margin: '0 auto',
  minHeight: '68vh',

  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    marginTop: '30px'
  }
}));

export const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.common.black};
  `
);

export const TypographySecondary = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.primary.light};
  `
);

export const WarningTwoToneIconWrapper = styled(WarningTwoToneIcon)(
  ({ theme }) => `
    color: ${theme.palette.warning.main};
`
);

export const FormContainer = styled('section')(({ theme }) => ({
  marginTop: '8px', // Added 'px' unit
  marginBottom: '28px', // Added 'px' unit
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: '#f1f1f1',
  border: `2px solid ${primaryColor}`, // Combined border properties into a single property
  borderRadius: '5px',
  padding: '20px',
  width: '100%',
  boxSizing: 'border-box', // Corrected 'borderBox' to 'border-box'
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    marginTop: '30px'
  }
}));

export const SpaceAfterTypography = styled('div')({
  marginBottom: '8px'
});

export const Container = styled('div')(({ theme }) => ({
  zIndex: '1',
  width: '100%',
  maxWidth: '1300px',
  marginRight: 'auto',
  marginLeft: 'auto',
  paddingRight: '10px',
  paddingLeft: '10px',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '5px',
    paddingLeft: '5px'
  }
}));

export const SectionContainer = styled('div')(({ theme }) => ({
  zIndex: '1',
  width: '100%',
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    paddingRight: '5px',
    paddingLeft: '5px'
  }
}));

export const Gen3Buttons = styled(
  ({
    action,
    fullwidth,
    fontbig,
    buttontype,
    type="button",
    ...props
  }: Gen3ButtonProps & Omit<ButtonProps, keyof Gen3ButtonProps>) => <Button type={type} {...props} />
)(({ action, fullwidth, fontbig, buttontype, theme }) => ({
  borderRadius: '25px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: action ? `2px solid ${secondaryColor}` : `2px solid ${primaryColor}`,
  backgroundColor: action ? `${secondaryColor}` : `${primaryColor}`,
  color: whiteColor,
  padding: fullwidth ? '12px 24px' : '10px 20px',
  fontSize: fontbig ? '20px' : '16px',
  fontWeight: buttontype ? 'normal' : 'bold',
  cursor: 'pointer',
  textAlign: 'center',
  width: fullwidth ? '100%' : 'auto',
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  '&:hover': {
    backgroundColor: action ? gen3SecondaryButtonHoverColor : gen3PrimaryButtonHoverColor,
    borderColor: action ? gen3SecondaryButtonHoverColor : gen3PrimaryButtonHoverColor,
  },

  // Tablet and smaller desktop screens
  '@media screen and (max-width: 1024px)': {
    padding: '8px 16px',
    fontSize: fontbig ? '18px' : '14px',
  },

  // Mobile landscape
  '@media screen and (max-width: 768px)': {
    fontSize: fontbig ? '16px' : '13px',
    width: fullwidth ? '90%' : 'auto',
    margin: '8px auto',
  },

  // Mobile portrait
  '@media screen and (max-width: 480px)': {
    padding: '5px 10px',
    fontSize: fontbig ? '14px' : '12px',
    width: fullwidth ? '85%' : 'auto',
    margin: '6px auto',
    borderRadius: '20px',
    border: action ? `1px solid ${secondaryColor}` : `1px solid ${primaryColor}`,
  }
}));

export const StyledIconButton = ({ action, fullwidth, fontbig, children, onClick, theme }) => {
  return (
    <button
      style={{
        borderRadius: '10px',
        display: 'inline-flex',
        flexDirection: 'row',
        borderColor: action ? secondaryColor : primaryColor,
        background: action ? secondaryColor : primaryColor,
        whiteSpace: 'nowrap',
        padding: fullwidth ? '10px 25%' : '5px 30px',
        color: action ? blackColor : whiteColor,
        fontSize: fontbig ? '20px' : '16px',
        fontWeight: 'bold',
        cursor: 'pointer',
        textAlign: 'center',
        width: fullwidth ? '100%' : '300px',
        fontFamily: 'Poppins, sans-serif'
      }}
      onClick={onClick}
    >
      <Email
        style={{
          fill: action ? blackColor : whiteColor, // Adjust the icon color based on your button style
          display: 'inherit',
          marginRight: '8px', // Adjust the spacing between icon and text
          marginLeft: '-4px'
        }}
      />
      {children}
    </button>
  );
};

export const DashboardActionButtons = styled(Button)<StyleProps>(
  ({ theme }) => `
  && {
    border: 1px solid ${primaryColor};
    color: ${primaryColor};
    paddng: 8px 10px;
    &:hover {
      color: ${gen3PrimaryButtonHoverColor}; /* Text color on hover */
      background-color: ligthen(${primaryColor}, 0.3);
    }
  }
`
);

export const ButtonLinks = styled(muiLink)<StyleProps>(
  ({ theme }) => `
  && {
    text-decoration: none;
    &:hover {
      color: ${gen3PrimaryButtonHoverColor};
      background-color: ligthen(${primaryColor}, 0.3);
      text-decoration: none;
    }
  }
`
);

export const PopOverAvatar = styled('div')(
  ({ theme }) => ` 
  cursor: 'pointer',
  padding: '0px 15px',
  fontWeight: 'bold',
  zIndex: '997',
  backgroundColor: theme.palette.common.white
`
);

export const SignupForm = styled('div')(
  ({ theme }) => ` 
  background-color: ${secondaryColor}
`
);

export const InfoSec = styled('div')<StyleProps>(
  ({ theme }) => `
  && {
    color: ${whiteColor};
    padding: 160px 0;
    background: ${({ lightbg }) => (lightbg ? primaryColor : '#101522')};
  }
`
);

export const InfoRow = styled('div')<StyleProps>(
  ({ theme }) => `
       && {
    display: flex;
    margin-right: 10px;
    margin-left: 20px;
    padding-left: 50px;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: ${({ imgstart }) => (imgstart ? 'row-reverse' : 'row')};

    @media screen and (max-width: 960px) {
      margin: 0px;
      padding: 0px;
      flex-direction: column; // Added to stack content on smaller screens
    }
  }
`
);

export const BannerText1 = styled('div')({
  marginRight: '85px',
  marginLeft: '65px',
  marginBottom: '15px',
  marginTop: '15px',
  padding: '0px'
});

export const BannerActionBox = styled('div')`
  && {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BannerContainerStyle = styled('div')<StyleProps>(
  () => `
  && {
    color: ${primaryColor};
    background: ${({ lightbg }) => (lightbg ? primaryColor : secondaryColor)};
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`
);

export const BannerCover = styled('div')({
  minWidth: '100%',
  backgroundImage: `url(${patternedBackground})`,
  backgroundRepeat: 'repeat',
  backgroundPosition: 'center',
  backgroundSize: 'auto',
  minHeight: '100%'
});

export const BannerRow = styled('div')<StyleProps>(
  () => `
  && {
    max-width: 100%;
    display: flex;
    align-items: center;
     justify-content: space-between;
  flex-wrap: nowrap;
  }
`
);
export const BannerCol1 = styled('div')`
  && {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 480px) and (max-width: 1200px) {
      max-width: 100%;
      flex-basis: 100%;
    }

    @media (min-width: 320px) and (max-width: 480px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }
`;

export const BannerCol2 = styled('div')`
  && {
    flex: 1; /* Ensure it fills the remaining space */
    display: flex;
    justify-content: center;
    align-items: stretch; /* Align items to fill the container */
    background: 'transparent';
    padding: 0; /* Remove any padding */
    margin: 0; /* Remove any margin */

      &&& {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  }
`;

export const BannerImg = styled('img')`
  && {
    padding-right: 0;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    height: 100%;
  }
  
  &&& {
      @media screen and (max-width: 768px) {
      display: none;
    }
  }

`;

export const StyledCard = styled('div')<StyleProps>(({ theme, layout2 }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: whiteColor,
  borderRadius: '15px',
  margin: '40px 0',
  padding: '60px',
  maxWidth: '345px', // Added 'px' unit
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)', // Fixed property name 'boxshadow' to 'boxShadow'

  flexDirection: layout2 || 'row',

  '& img': {
    width: '80%'
  },

  '& > div': {
    flex: 1
  },

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const SectionRectangle = styled('div')<StyleProps>(
  ({ theme }) => `
  && {
    color: ${whiteColor};
    height: 80px;
    width: 135%;
    background: ${({ lightbg }) => (lightbg ? primaryColor : '#101522')};
  }
`
);

export const AboutUsRow = styled('div') <StyleProps>`
  && {
    display: flex;
    margin: 10px -15px -15px -15px;
    flex-wrap: wrap;
    align-items: left;
    flex-direction: ${({ imgstart }) => (imgstart ? 'row-reverse' : 'row')};

    @media screen and (max-width: 960px) {
      margin: 0px;
    }
  }
`;

export const LeftColumn = styled('div')`
  && {
    flex: 1;
    max-width: 50%;
    min-height: 100%;
    flex-basis: 50%;
  }
`;

export const RightColumn = styled('div')`
  && {
    flex: 1;
    max-width: 50%;
    min-height: 100%;
    flex-basis: 50%;
  }
`;

export const AboutUsColumn = styled('div')`
  && {
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;

    @media (min-width: 480px) and (max-width: 1200px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    @media (min-width: 320px) and (max-width: 480px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const AboutUsTitle = styled('h2')(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 700,
  color: primaryColor, // Your primary color
  textAlign: 'center',
  marginBottom: '1.5rem',
  position: 'relative',
  padding: '0.5rem 0',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80px',
    height: '4px', // Your secondary/accent color
    borderRadius: '2px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem'
  }
}));

export const SectionTitle = styled('h3')(({ theme }) => ({
  fontSize: '2.0rem',
  fontWeight: 650,
  color: gen3SectionTextColor, // Your primary color
  textAlign: 'center',
  marginBottom: '1.5rem',
  position: 'relative',
  padding: '0.5rem 0',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80px',
    height: '4px', // Your secondary/accent color
    borderRadius: '2px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem'
  }
}));


export const SectionTitleAlternate = styled('h3')(({ theme }) => ({
  fontSize: '2.0rem',
  fontWeight: 650,
  color: gen3AlternateSectionTextColor, // Your primary color
  textAlign: 'center',
  marginBottom: '1.5rem',
  position: 'relative',
  padding: '0.5rem 0',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80px',
    height: '4px', // Your secondary/accent color
    borderRadius: '2px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem'
  }
}));

export const AboutUsText = styled('div')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-size: 28px;
    line-height: 40px;
    color: ${blackColor};
    flex: 1;
    padding: 20px;
    margin: 0px;

    @media screen and (max-width: 960px) {
      margin: 0px;
    }
  }
`;

export const SectionHeaderWrapper = styled('div')`
  && {
    padding-top: 0;
  }
`;

export const InfoColumn = styled('div')`
  && {
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;

    @media (min-width: 480px) and (max-width: 1200px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    @media (min-width: 320px) and (max-width: 480px) {
      max-width: 100%;
      flex-basis: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const ImgWrapper = styled('div') <StyleProps>`
  && {
    max-width: 555px;
    display: flex;
    justify-content: ${({ $start }) => ($start ? 'flex-start' : 'flex-end')};
  }
`;

export const Img = styled('img')`
  && {
    padding-right: 0;
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;

  }
`;

export const HomeMetrics = styled('div')`
  && {
    background: ${gen3BannerStripeColor};
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    padding: 20px 0;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 10px 0;
    }
  }
`;

export const MetricsSubTitle = styled('div') <StyleProps>`
  && {
    font-size: 20px;
    font-weight: 500;
    color: ${gen3BannerStripeTextColor};
  }
`;

export const CountContent = styled('div') <StyleProps>`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px 20px;
    text-align: center;
    flex: 1;

    @media screen and (max-width: 768px) {
      margin: 10px;
      width: 100%;
    }
  }
`;

export const CountNumber = styled('div') <StyleProps>`
  && {
    font-size: 25px;
    font-weight: 600;
    color: ${gen3BannerStripeTextColor};
    margin-bottom: 5px;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
`;

export const CountLabel = styled('div') <StyleProps>`
  && {
    font-size: 30px;
    font-weight: 600;
    color: ${gen3BannerStripeTextColor};
    white-space: nowrap;

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

export const Nav = styled('nav')(
  ({ theme }) => `
  && {
    background: ${primaryColor};
    height: 80px;
    display: inline-flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    font-size: 1.2rem;

    @media screen and (max-width: 1280px) {
      padding: 0.5rem 0.5rem;
      font-size: 1rem;
    }
  }
`
);

export const NavbarContainer = styled('div')`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflowx: hidden;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: auto;
    }
  }
`;

export const NavLogo = styled(Link)`
  && {
    color: ${whiteColor};
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1280px) {
      margin-bottom: 1rem;
    }
  }
`;

export const NavIcon = styled('img')`
  && {
    height: 5rem;

    @media screen and (max-width: 1280px) {
      height: 3rem;
      margin-right: 0.5rem;
    }
  }
`;

export const NavMenu = styled('ul') <StyleProps>`
  && {
    display: flex;
    align-items: center;
    list-style: none; /* Corrected property name */
    text-align: center;
    flex-flow: row nowrap;
    color: white;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      width: 100%;
      padding: 0;
      margin: 0;
    }
  }
`;

export const NavItem = styled('li') <StyleProps>`
  && {
    height: 80px;
    border-bottom: 2px solid transparent;
    border-radius: 2px;
    padding-top: 25px;
    font-size: 1.5rem;
    font-family: 'Poppins, sans-serif';

    &:hover {
      border-bottom: 4px solid ${whiteColor};
    }

    @media screen and (max-width: 1280px) {
      width: 100%;
      height: auto;
      text-align: center;
      padding: 0.5rem 0;
      font-size: 1.2rem; /* Corrected property name */

      &:hover {
        border-bottom: none;
      }
    }
  }
`;

export const NavLinks = styled(HashLink)(
  ({ theme }) => `
  color: ${whiteColor};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  flexshrink: 0;

  &:hover {
    color: ${tertiaryColor};
    transition: all 0.3s ease;
  }

  @media screen and (max-width: 1280px) {
    display: block;
    padding: 0.5rem 0;
  }
`
);

export const NavItemBtn = styled('li')`
  && {
    @media screen and (max-width: 1280px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
`;

export const NavButton = styled(Button)<Gen3ButtonProps>(
  ({ theme }) => `
  border-radius: 20px; // Removed single quotes
  padding: 8px 16px; // Removed single quotes
  margin: 0 4px; // Removed single quotes
  font-size: 14px; // Removed single quotes
  font-weight: bold; // Removed single quotes
  text-transform: uppercase; // Removed single quotes
  letter-spacing: 0.5px; // Removed single quotes
  color: ${({ activeButton }) => (activeButton ? whiteColor : primaryColor)};
  background-color: ${({ activeButton }) => (activeButton ? secondaryColor : primaryColor)};
  border: 2px solid ${primaryColor};
  transition: all 0.2s ease-out;
  &:hover: {
    background-color: ${({ activeButton }) => (activeButton ? secondaryColor : primaryColor)};
    color: ${whiteColor},
    box-shadow: '0 6px 8px rgba(0, 0, 0, 0.15)',
    transform: 'translateY(-2px)'
  },
  &:active: {
    transform: 'translateY(1px)',
    box-shadow: 'none'
  },
  &::after: {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 0,
    height: 0,
    background: 'rgba(255, 255, 255, 0.3)',
    border-radius: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'width 0.2s ease-out, height 0.2s ease-out'
  }
`
);

export const NavBtnLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
  border: none;
  outline: none;

  @media screen and (max-width: 1280px) {
    display: flex;
    flex-direction: column;
  }
`;

export const HamburgerIcon = styled('div')`
  && {
    display: none;

    @media screen and (max-width: 960px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  }
`;

export const LandingContent = styled('div')`
  && {
    @media (max-width: 1200px) {
      padding: 0.25rem 0.25rem;
    }
  }
`;

export const ProgramContainer = styled('div')`
  && {
    margin-top: 50px 0px;
    padding-top: 30px;

    @media screen and (max-width: 1200px) {
      padding: 20px;
      margin-top: 30px;
    }
  }
`;

export const ProgramWrapper = styled('div')`
  && {
    margin: 0 auto; // Center the line with equal space on both sides
  }
`;

export const ProgramTitle = styled('span')`
  && {
    margin: 15px 10px;
    font-size: 25px;
    font-weight: 600;
    font-family: 'Poppins, sans-serif';
    color: ${whiteColor};
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

export const ProgramCard = styled('div')`
  && {
    padding: 30px 20px;
    background-color: ${gen3SectionColor};

    @media screen and (max-width: 1200px) {
      padding: 0px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
`;

export const ProgramCardAlternate = styled('div')(
  ({ theme }) => `
  && {
    padding: 30px 120px;
    background-color: ${gen3AlternateSectionColor};

    @media screen and (max-width: 1200px) {
      padding: 0px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
`
);

export const LandingPageActions = styled('div')`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const CardContainer = styled('div')`
  && {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 480px) and (max-width: 1200px) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    @media (min-width: 320px) and (max-width: 480px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
`;

export const AnalyticsTilesContainer = styled('div')`
  && {
    background: #d1f6ff;
    width: 98%;
    height: 162px;
    border-radius: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 475px) {
      flex-direction: column;
      margin-top: 10px;
      padding: 10px;
      height: 100%;
    }
  }
`;

export const AnalyticsTilesWrapper = styled('div')`
  && {
    display: grid;
    justify-items: center;
  }
`;

export const AnalyticsTilesTitle = styled('div')`
  && {
    height: 27.35px;
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0px 30px 0;
    text-align: center;
    letter-spacing: 0.03em;
    color: ${blackColor};

    @media screen and (max-width: 475px) {
      font-size: 20px;
      height: 20px;
    }
  }
`;

export const NoDatafound = styled('div')`
  && {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    margin: 15px 0;
  }
`;

export const AnalyticsTilesCount = styled('div')`
  && {
    height: 27.35px;
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    margin: 0 0px 27px 0;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.03em;
    color: ${blackColor};
    @media screen and (max-width: 475px) {
      font-size: 25px;
      height: 10px;
    }
  }
`;

export const VerticalLine = styled('div')`
  && {
    width: 1px;
    background-color: rgb(87, 86, 86);
    height: 70%;
    float: left;

    @media screen and (max-width: 475px) {
      width: 0px;
    }
  }
`;

export const HourTilesContainer = styled('div')`
  && {
    height: 162px;
    background: #ddfdf7;
    border-radius: 9px;
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

    @media screen and (max-width: 475px) {
      display: flex;
      flex-direction: column;
      padding: 5px;
    }
  }
`;

export const TilesHeader = styled('div')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: ${blackColor};
    padding: 25px 0 0 0;

    @media screen and (max-width: 475px) {
      padding: 0px;
      margin: 0px;
    }
  }
`;

export const HourMTitle = styled('span')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: ${blackColor};

    @media screen and (max-width: 475px) {
      padding: 0px;
      margin: 0px;
    }
  }
`;

export const TimeDivider = styled('span')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: ${blackColor};
    margin: 0 3px;

    @media screen and (max-width: 475px) {
      padding: 0px;
      margin: 0px;
    }
  }
`;

export const MinutesStyle = styled('span')`
  && {
    font-family: 'Poppins, sans-serif';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: ${blackColor};
    margin: 0px 5px;

    @media screen and (max-width: 475px) {
      font-size: 20px;
      padding: 5px;
      margin: 0px;
    }
  }
`;

export const LoggedTimeDetails = styled('span')`
  && {
    padding: 0 0 40px 0;

    @media screen and (max-width: 475px) {
      padding: 0px;
      margin: 0px;
    }
  }
`;

export const ClassMetricsText = styled(Text)(
  ({ theme }) => `
  && {
    font-style: bold;
    color: ${primaryColor};
    font-family: 'Poppins, sans-serif';
    font-size: 20px;
    font-weight: bold;
  }
`
);

export const ClassMetricsTitle = styled(Text)(
  ({ theme }) => `
  && {
    font-family: 'Poppins, sans-serif';
    font-size: 15px;
  }
`
);

export const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: hidden;

      .MuiTabs-indicator {
        box-shadow: none;
      }
    }
`
);

export default GlobalStyles;
