// features/login/reducers/loginReducer.ts
import { LoginActionTypes, LoginAction } from '../actions/LoginActionTypes';
import { initialState, LoginState } from '../../../models/login';

const loginReducer = (state = initialState, action: LoginAction): LoginState => {
  switch (action.type) {
    case LoginActionTypes.LOGIN_USER:
      return { ...state, loading: true };
    case LoginActionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
        loggedIn: action.payload.loggedIn,
        newPasswordRequired: action.payload.newPasswordRequired
      };
    case LoginActionTypes.LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        loggedIn: action.payload.loggedIn,
        loginError: action.payload.loginError,
        errorMessage: action.payload.errorMessage,
        newPasswordRequired: action.payload.newPasswordRequired
      };
    case LoginActionTypes.LOGIN_ACCOUNT:
      return { ...state, loading: true };
    case LoginActionTypes.LOGIN_ACCOUNT_SUCCESS:
      return { ...state, account: action.payload, loading: false, error: null };
    case LoginActionTypes.LOGIN_ACCOUNT_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default loginReducer;
