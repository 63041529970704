import { takeLatest, put, call } from 'redux-saga/effects';
import { CareersApplyActionTypes } from '../actions/careersApplyActionTypes';
import {
  careersApplyRequestSuccess,
  careersApplyRequestError,
  careersApplyUpdateRequestSuccess,
  careersApplyUpdateRequestError,
  careersApplyDeleteRequestSuccess,
  careersApplyDeleteRequestError,
  careersApplyMessageRequestError,
  careersApplyMessageRequestSuccess
} from '../actions/careersApplyActions';
import { apiService } from '../../../services/api';

function* careersApplyRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.careersApply, req.payload);

    // Dispatch the success action with the API response
    yield put(careersApplyRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(careersApplyRequestError(error.message));
  }
}

function* careersApplyUpdateRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageCareersApply, req.payload);

    // Dispatch the success action with the API response
    yield put(careersApplyUpdateRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(careersApplyUpdateRequestError(error.message));
  }
}

function* careersApplyDeleteRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageCareersApply, req.payload);

    // Dispatch the success action with the API response
    yield put(careersApplyDeleteRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(careersApplyDeleteRequestError(error.message));
  }
}

function* careersApplyMessageRequestSaga(req) {
  try {
    const response = yield call(apiService.manageCareersApply, req.payload);

    yield put(careersApplyMessageRequestSuccess(response));
  } catch (error: any) {
    yield put(careersApplyMessageRequestError(error.message));
  }
}

export function* watchCareersApplyRequest() {
  yield takeLatest(CareersApplyActionTypes.CAREERS_APPLY_REQUEST, careersApplyRequestSaga);
  yield takeLatest(CareersApplyActionTypes.CAREERS_APPLY_UPDATE_REQUEST, careersApplyUpdateRequestSaga);
  yield takeLatest(CareersApplyActionTypes.CAREERS_APPLY_DELETE_REQUEST, careersApplyDeleteRequestSaga);
  yield takeLatest(CareersApplyActionTypes.CAREERS_APPLY_MESSAGE_REQUEST, careersApplyMessageRequestSaga);
}
