import React, { useState, ReactElement, Ref, forwardRef, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { numeral } from 'numeral';

import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  FormControl,
  Select,
  InputLabel,
  Zoom,
  InputAdornment,
  styled,
  SelectChangeEvent
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import type { Invoice, InvoiceStatus } from '../../models/invoice';
import { useTranslation } from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from '../../Components/Label';
import BulkActions from './BulkActions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import { format, formatDistance } from 'date-fns';
import { CheckCircleOutline } from '@mui/icons-material';
import { AppState, dispatch } from '../../store/configureStore';
import { useSelector } from 'react-redux';
import { updateInvoiceRequest } from '../../features/Invoices/actions/invoiceActions';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

interface ResultsProps {
  invoices: Invoice[];
}

interface Filters {
  status?: InvoiceStatus | null;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const getInvoiceStatusLabel = (invoiceStatus: InvoiceStatus): JSX.Element => {
  const map = {
    pending: {
      text: 'Invoiced',
      color: 'warning'
    },
    completed: {
      text: 'Approved',
      color: 'success'
    },
    draft: {
      text: 'Rejected',
      color: 'error'
    },
    progress: {
      text: 'Paid',
      color: 'primary'
    }
  };

  const { text, color }: any = map[invoiceStatus];

  return (
    <Label color={color}>
      <b>{text}</b>
    </Label>
  );
};

const applyFilters = (
  invoices: Invoice[],
  query: string,
  filters: Filters
): Invoice[] => {
  return invoices.filter((invoice) => {
    let matches = true;

    if (query) {
      const properties = ['attendee_name'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (invoice[property]?.toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    if (filters.status && invoice.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (
  invoices: Invoice[],
  page: number,
  limit: number
): Invoice[] => {
  return invoices.slice(page * limit, page * limit + limit);
};

const Results: FC<ResultsProps> = ({ invoices }) => {
  const [selectedItems, setSelectedInvoices] = useState<string[]>([]);
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const username: any = useSelector((state: AppState) => state.login?.account?.username);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [query, setQuery] = useState<string>('');
  const [filters, setFilters] = useState<Filters>({
    status: null
  });

  const statusOptions = [
    {
      id: 'all',
      name: 'Show all'
    },
    {
      id: 'Invoiced',
      name: t('Invoiced')
    },
    {
      id: 'Approved',
      name: t('Approved')
    },
    {
      id: 'Rejected',
      name: t('Rejected')
    },
    {
      id: 'Paid',
      name: t('Paid')
    }
  ];

  const idToken: any = useSelector((state: AppState) => state.login.user.idToken);
  const user_api: any = useSelector((state: AppState) => state.login.account.userAPI);
  const invoice_loader: any = useSelector((state: AppState) => state?.invoiceReducer?.loading);
  const user_id = localStorage.getItem('userId');

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleStatusChange = (e: SelectChangeEvent): void => {
    let value: any = null;

    if (e.target.value !== 'all') {
      value = e.target.value;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value
    }));
  };

  const handleSelectAllInvoices = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSelectedInvoices(
      event.target.checked ? invoices.map((invoice) => invoice.id) : []
    );
  };

  const handleSelectOneInvoice = (
    event: ChangeEvent<HTMLInputElement>,
    invoiceId: string
  ): void => {
    if (!selectedItems.includes(invoiceId)) {
      setSelectedInvoices((prevSelected) => [...prevSelected, invoiceId]);
    } else {
      setSelectedInvoices((prevSelected) =>
        prevSelected.filter((id) => id !== invoiceId)
      );
    }
  };

  const handlePageChange = (event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value));
  };

  const filteredInvoices = applyFilters(invoices, query, filters);
  const paginatedInvoices = applyPagination(filteredInvoices, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSomeInvoices =
    selectedItems.length > 0 && selectedItems.length < invoices.length;
  const selectedAllInvoices = selectedItems.length === invoices.length;

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);

    enqueueSnackbar(t('Delete action completed successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });
  };

  const handleStatus = (invoiceId, status) => {
    dispatch(updateInvoiceRequest({
      invoice_id: invoiceId,
      user_api,
      user_id,
      idToken,
      status
    }))
  };

  return (
    <React.Fragment>
      <Card
        sx={{
          p: 2,
          mb: 3,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Grid alignItems="center" container spacing={3}>
          <Grid item xs={12} lg={7} md={6}>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
              sx={{
                m: 0
              }}
              onChange={handleQueryChange}
              placeholder={t('Search invoices by student name ...')}
              value={query}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} lg={5} md={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('Status')}</InputLabel>
              <Select
                value={filters.status || 'all'}
                onChange={handleStatusChange}
                label={t('Status')}
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.id} value={statusOption.id}>
                    {statusOption.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
      <Card>
        <Box pl={2} display="flex" alignItems="center">
          <Checkbox
            checked={selectedAllInvoices}
            indeterminate={selectedSomeInvoices}
            onChange={handleSelectAllInvoices}
          />
          {selectedBulkActions && (
            <Box flex={1} p={2}>
              <BulkActions />
            </Box>
          )}
          {!selectedBulkActions && (
            <Box
              flex={1}
              p={2}
              display={{ xs: 'block', sm: 'flex' }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography component="span" variant="subtitle1">
                  {t('Showing')}:
                </Typography>{' '}
                <b>{paginatedInvoices.length}</b> <b>{t('invoices')}</b>
              </Box>
              <TablePagination
                component="div"
                count={filteredInvoices.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          )}
        </Box>
        <Divider />

        {paginatedInvoices.length === 0 ? (
          <Typography
            sx={{
              py: 10
            }}
            variant="h3"
            fontWeight="normal"
            color="text.secondary"
            align="center"
          >
            {t("We couldn't find any invoices matching your search criteria")}
          </Typography>
        ) : (
          <React.Fragment>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('#')}</TableCell>
                    <TableCell>{t('User ID')}</TableCell>
                    <TableCell>{t('Student Name')}</TableCell>
                    <TableCell>{t('Student Email')}</TableCell>
                    <TableCell>{t('Invoice ID')}</TableCell>
                    <TableCell>{t('Total Fee')}</TableCell>
                    <TableCell>{t('Status')}</TableCell>
                    <TableCell align="center">{t('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedInvoices.map((invoice, index) => {
                    const status: any = invoice?.status
                    return (
                      <TableRow key={invoice.invoice_id} hover>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          <Typography noWrap>{username}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography >{invoice.attendee_name}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {invoice?.attendee_email?.primary || invoice?.attendee_email?.secondary || (invoice?.attendee_email ? invoice?.attendee_email : '')}
                          </Typography>

                        </TableCell>
                        <TableCell>
                          <Typography>{invoice.invoice_id}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography noWrap>{`$${invoice.total_fee}`}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            noWrap
                            sx={{
                              color: status === 'Approved' ? 'green' : status === 'Rejected' ? 'red' : 'orange',
                              fontWeight: 'bold',
                            }}
                          >
                            {status}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={t('Approve')} arrow>
                            <IconButton
                              onClick={() => handleStatus(invoice.invoice_id, 'Approved')}
                              color="primary"
                              disabled={status === 'Approved' || status === 'Rejected' || invoice_loader}
                            >
                              <CheckCircleOutline fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t('Reject')} arrow>
                            <IconButton
                              onClick={() => handleStatus(invoice.invoice_id, 'Rejected')}
                              color="error"
                              disabled={status === 'Approved' || status === 'Rejected' || invoice_loader}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <Box p={2}>
              <TablePagination
                component="div"
                count={filteredInvoices.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          </React.Fragment>
        )}
      </Card>

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography
            align="center"
            sx={{
              pt: 4,
              px: 6
            }}
            variant="h3"
          >
            {t('Do you really want to delete this invoice')}?
          </Typography>

          <Typography
            align="center"
            sx={{
              pt: 2,
              pb: 4,
              px: 6
            }}
            fontWeight="normal"
            color="text.secondary"
            variant="h4"
          >
            {t("You won't be able to revert after deletion")}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{
                mx: 1
              }}
              onClick={closeConfirmDelete}
            >
              {t('Cancel')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{
                mx: 1,
                px: 3
              }}
              variant="contained"
            >
              {t('Delete')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </React.Fragment>
  );
};

// Results.propTypes = {
//   invoices: PropTypes.array.isRequired
// };


export default Results;
