import { ScheduleActionTypes, ScheduleAction } from './getScheduleActionTypes';

export const getScheduleRequest = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.GET_SCHEDULE_REQUEST,
  payload: data
});

export const getRescheduleRequest = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.GET_RESCHEDULE_REQUEST,
  payload: data
});

export const getRescheduleAndScheduleRequest = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.GET_RESCHEDULE_AND_SCHEDULE_REQUEST,
  payload: data
});

export const getRescheduleRequestSuccess = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.GET_RESCHEDULE_REQUEST_SUCCESS,
  payload: data
});

export const getScheduleRequestSuccess = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.GET_SCHEDULE_REQUEST_SUCCESS,
  payload: data
});

export const getRescheduleRequestError = (error: string): ScheduleAction => ({
  type: ScheduleActionTypes.GET_RESCHEDULE_REQUEST_ERROR,
  payload: error
});

export const getScheduleRequestError = (error: string): ScheduleAction => ({
  type: ScheduleActionTypes.GET_SCHEDULE_REQUEST_ERROR,
  payload: error
});

export const updateScheduleRequest = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST,
  payload: data
});

export const updateScheduleRequestSuccess = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST_SUCCESS,
  payload: data
});

export const updateteacherScheduleRequestSuccess = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.UPDATE_Teacher_SCHEDULE_REQUEST_SUCCESS,
  payload: data
});

export const updateScheduleRequestError = (error: string): ScheduleAction => ({
  type: ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST_ERROR,
  payload: error
});

export const updateTeacherScheduleRequestError = (error: string): ScheduleAction => ({
  type: ScheduleActionTypes.UPDATE_Teacher_SCHEDULE_REQUEST_ERROR,
  payload: error
});

export const deleteScheduleRequest = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.DELETE_SCHEDULE_REQUEST,
  payload: data
});

export const deleteScheduleRequestSuccess = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.DELETE_SCHEDULE_REQUEST_SUCCESS,
  payload: data
});

export const deleteScheduleRequestError = (error: string): ScheduleAction => ({
  type: ScheduleActionTypes.DELETE_SCHEDULE_REQUEST_ERROR,
  payload: error
});

export const refetchSchedule = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.GET_RESCHEDULE_REQUEST_REFETCH,
  payload: data
});

export const getPastScheduleRequest = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.GET_PAST_SCHEDULE_REQUEST,
  payload: data
});

export const getPastScheduleRequestSuccess = (data: any): ScheduleAction => ({
  type: ScheduleActionTypes.GET_PAST_SCHEDULE_REQUEST_SUCCESS,
  payload: data
});

export const getPastScheduleRequestError = (error: string): ScheduleAction => ({
  type: ScheduleActionTypes.GET_PAST_SCHEDULE_REQUEST_ERROR,
  payload: error
});
