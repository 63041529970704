import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const DialogTitleWithClose = ({ title, handleClose }) => {


  return (
    <Box sx={{ padding: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="h3" sx={{ fontWeight: 'bold', margin: 3, textAlign: 'center' }}>
        {title}
      </Typography>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default DialogTitleWithClose;