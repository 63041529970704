
export enum InvoicesActionTypes {
  GET_INVOICES = 'Invoices/GET_INVOICES',
  GET_INVOICES_SUCCESS = 'Invoices/GET_INVOICES_SUCCESS',
  GET_INVOICES_ERROR = 'Invoices/GET_INVOICES_ERROR',
  CREATE_INVOICES = 'Invoices/CREATE_INVOICES',
  CREATE_INVOICES_SUCCESS = 'Invoices/CREATE_INVOICES_SUCCESS',
  CREATE_INVOICES_ERROR = 'Invoices/CREATE_INVOICES_ERROR',
  UPDATE_INVOICES = 'Invoices/UPDATE_INVOICES',
  UPDATE_INVOICES_SUCCESS = 'Invoices/UPDATE_INVOICES_SUCCESS',
  UPDATE_INVOICES_ERROR = 'Invoices/UPDATE_INVOICES_ERROR'
}

export interface InvoicesAction {
  type: InvoicesActionTypes;
  payload?: any;
}
