import { ScheduleActionTypes, ScheduleAction } from '../actions/getScheduleActionTypes';

interface ScheduleState {
  scheduleData: string[];
  pastScheduleData: string[];
  rescheduleData: string[];
  updateScheduleData: string[];
  deleteScheduleData: string[];
  scheduleLoading: boolean;
  pastScheduleLoading: boolean;
  updatingSchedule: boolean;
  deletingSchedule: boolean;
  rescheduleLoading: boolean;
  scheduleLoaded: boolean;
  pastScheduleLoaded: boolean;
  rescheduleLoaded: boolean;
  scheduleError: string | null;
  pastScheduleError: string | null;
  updateScheduleError: string | null;
  deleteScheduleError: string | null;
  refetch: boolean;
  rescheduleError: string | null;
}

const initialState: ScheduleState = {
  scheduleData: [],
  pastScheduleData: [],
  rescheduleData: [],
  updateScheduleData: [],
  deleteScheduleData: [],
  scheduleLoading: false,
  scheduleLoaded: false,
  pastScheduleLoading: false,
  pastScheduleLoaded: false,
  updatingSchedule: false,
  deletingSchedule: false,
  rescheduleLoading: false,
  rescheduleLoaded: false,
  scheduleError: null,
  updateScheduleError: null,
  deleteScheduleError: null,
  refetch: false,
  rescheduleError: null,
  pastScheduleError: null,
};

const scheduleReducer = (state = initialState, action: ScheduleAction): ScheduleState => {
  switch (action.type) {
    case ScheduleActionTypes.GET_SCHEDULE_REQUEST:
      return { ...state, scheduleLoading: true, refetch: false };
    case ScheduleActionTypes.GET_RESCHEDULE_REQUEST:
      return { ...state, rescheduleLoading: true, refetch: false };
    case ScheduleActionTypes.GET_RESCHEDULE_REQUEST_SUCCESS:
      if (action.payload?.action !== 'get') {
        return { ...state, rescheduleData: action.payload, rescheduleLoading: false, rescheduleLoaded: true, rescheduleError: null };
      }
      return { ...state, rescheduleData: action.payload, rescheduleLoading: false, rescheduleLoaded: true, rescheduleError: null };
    case ScheduleActionTypes.GET_RESCHEDULE_REQUEST_ERROR:
      return { ...state, rescheduleLoading: false, rescheduleError: action.payload };
    case ScheduleActionTypes.GET_SCHEDULE_REQUEST_SUCCESS:
      return {
        ...state,
        scheduleData: action.payload,
        scheduleLoading: false,
        scheduleLoaded: true,
        scheduleError: null,

      };
    case ScheduleActionTypes.GET_SCHEDULE_REQUEST_ERROR:
      return { ...state, scheduleLoading: false, scheduleError: action.payload };
    case ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST:
      return { ...state, updatingSchedule: true };
    case ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST_Data:
      return { ...state, updateScheduleData: action.payload }
    case ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST_SUCCESS:
      return { ...state, updateScheduleData: action.payload, updatingSchedule: false, updateScheduleError: null };
    case ScheduleActionTypes.UPDATE_SCHEDULE_REQUEST_ERROR:
      return { ...state, updatingSchedule: false, updateScheduleError: action.payload };
    case ScheduleActionTypes.DELETE_SCHEDULE_REQUEST:
      return { ...state, deletingSchedule: true };
    case ScheduleActionTypes.DELETE_SCHEDULE_REQUEST_SUCCESS:
      return { ...state, deleteScheduleData: action.payload, deletingSchedule: false, deleteScheduleError: null };
    case ScheduleActionTypes.DELETE_SCHEDULE_REQUEST_ERROR:
      return { ...state, deletingSchedule: false, deleteScheduleError: action.payload };
    case ScheduleActionTypes.GET_RESCHEDULE_REQUEST_REFETCH:
      return { ...state, refetch: true };
    case ScheduleActionTypes.GET_PAST_SCHEDULE_REQUEST:
      return { ...state, pastScheduleLoading: true };
    case ScheduleActionTypes.GET_PAST_SCHEDULE_REQUEST_SUCCESS:
      return { ...state, pastScheduleData: action.payload, pastScheduleLoading: false, pastScheduleLoaded: true, pastScheduleError: null };
    case ScheduleActionTypes.GET_PAST_SCHEDULE_REQUEST_ERROR:
      return { ...state, pastScheduleLoading: false, pastScheduleError: action.payload };
    default:
      return state;
  }
};

export default scheduleReducer;
