import { getIn } from "formik";

const ENV = process.env.REACT_APP_LANE;
const BASE_URL = `https://${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_LANE}`;
export const apiService = {
  login: async (req: any) => {
    // Simulate an asynchronous API callu
    const response = await fetch(`${BASE_URL}/authenticateuser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: req?.useremail,
        password: req?.password
      })
    });
    return response.json();
  },
  registerUser: async (req: any) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/registeruser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: req?.email,
        password: req?.password,
        phoneNumber: req?.phone_number,
        registration_code: req?.registration_code
      })
    });
    return response.json();
  },
  ForgotPwd: async (req: any) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/forgotpassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: req.email
      })
    });
    return response.json();
  },
  courseListing: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/getCourseList`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  courseDetail: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/getCourseDetail`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  classModels: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${BASE_URL}/getClassModel`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getCareers: async (req) => {
    const response = await fetch(`${BASE_URL}/getCareers`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  careersApply: async (req) => {
    const response = await fetch(`${BASE_URL}/careersApply`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageCareersApply: async (req) => {
    const response = await fetch(`${BASE_URL}/manageCareersApply`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  updateCareers: async (req) => {
    const response = await fetch(`${req?.user_api}/${ENV}/updateCareers`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  createInvoice: async (req) => {
    const response = await fetch(`${req?.user_api}/${ENV}/updateInvoice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  updateInvoice: async (req) => {
    const response = await fetch(`${req?.user_api}/${ENV}/updateInvoice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  classNotes: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${req?.user_api}/${ENV}/GetNotes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  teacherRegisterStudent: async (req) => {
    console.log('req', req);
    // Simulate an asynchronous API call
    const response = await fetch(`${req?.user_api}/${ENV}/EnrollClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  userTasks: async (req) => {
    // Simulate an asynchronous API call
    console.log('req', req);
    const response = await fetch(`${req?.user_api}/${ENV}/manageTasks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getApplicants: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${req?.user_api}/${ENV}/getApplicants`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageApplicants: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${req?.user_api}/${ENV}/manageApplicants`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageToDo: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${req?.user_api}/${ENV}/manageToDo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  ConfirmRegistration: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  EventRegistration: async (req: any) => {
    const response = await fetch(`${BASE_URL}/RegisterEvents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  MakePayment: async (req: any) => {
    const response = await fetch(`${BASE_URL}/MakePayment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  markClassAttendance: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/manageAttendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  UserMetrics: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/UserMetrics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  createClass: async (req: any) => {
    console.log("create class data inside request")
    console.log(req)
    const response = await fetch(`${req?.formData?.user_api}/${ENV}/ProvideClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getResources: async (req: any) => {
    const response = await fetch(`${BASE_URL}/getResources`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });

    return response.json();
  },
  getLocations: async (req: any) => {
    const response = await fetch(`${BASE_URL}/getLocations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getTasks: async (req: any) => {
    const response = await fetch(`${BASE_URL}/getTasks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getInvoices: async (req) => {
    console.log("get invoices data inside request")
    console.log(req)
    const response = await fetch(`${req?.user_api}/${ENV}/getInvoice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      },
      body: JSON.stringify(req),
    });
    console.log("data")
    console.log(response)
    return response.json();
  },
  manageResources: async (req: any) => {
    console.log(JSON.stringify(req, null, 2));
    const response = await fetch(`${req?.user_api}/${ENV}/manageResources`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  bookResource: async (req: any) => {
    const response = await fetch(`${BASE_URL}/bookResource`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    console.log(response);
    return response.json();
  },
  manageCourse: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/manageCourse`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageCategories: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/manageCategories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageLocations: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/manageLocations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getEvents: async (req: any) => {
    const response = await fetch(`${BASE_URL}/GetEvents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  manageEvents: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/UpdateEvents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  createHomework: async (req: any) => {
    const response = await fetch(`${req?.formData?.user_api}/${ENV}/CreateHomework`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  enrollClass: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/EnrollClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getStudents: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/GetStudents`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getClasses: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/GetClasses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getProfile: async (req: any) => {
    console.log('req', req);
    const response = await fetch(`${req.user_api}/${ENV}/getprofile?id=${req.id}&profile=${req.groups}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      }
    });

    return response.json();
  },
  getUserProfile: async (req: any) => {
    const response = await fetch(`${BASE_URL}/GetProfile?userId=${req.userId}&profile=${req.groups}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.json();
  },
  getCourseLevels: async (req: any) => {
    console.log('requestHomework req', req);
    const response = await fetch(`${req.homework_api}/${ENV}/getCourseLevels`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      },
      body: JSON.stringify(req)
    });

    return response.json();
  },
  requestHomework: async (req: any) => {
    console.log('requestHomework req', req);
    const response = await fetch(`${req.homeworkParams.homework_api}/${ENV}/requestHomework`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.homeworkParams.idToken}`
      },
      body: JSON.stringify(req.homeworkParams)
    });

    return response.json();
  },
  getHomework: async (req: any) => {
    console.log('getHomework req', req);
    const response = await fetch(`${req.homework_api}/${ENV}/getHomework`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      },
      body: JSON.stringify(req)
    });

    return response.json();
  },
  getHomeworkPages: async (req: any) => {
    console.log('getHomeworkPages req', req);
    const response = await fetch(`${req.homework_api}/${ENV}/getHomeworkPages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      },
      body: JSON.stringify(req)
    });

    return response.json();
  },
  getHomeworkQuestions: async (req: any) => {
    //check this out
    console.log('getHomeworkQuestions req', req);
    const response = await fetch(`${req.homework_api}/${ENV}/getHomeworkPages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      },
      body: JSON.stringify(req)
    });

    return response.json();
  },
  updateHomeworkQuestions: async (req: any) => {
    const response = await fetch(`${req.homework_api}/${ENV}/gradeHomework`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      },
      body: JSON.stringify(req)
    });

    return response.json();
  },
  updateProfile: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/UpdateProfile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  updateClass: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/updateClasses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  requestClass: async (req: any) => {
    const response = await fetch(`${req?.formData?.user_api}/${ENV}/RequestClass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getMessages: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/GetMessages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  updateMessages: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/UpdateMessages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  sendNotifications: async (req: any) => {
    const response = await fetch(`${BASE_URL}/Notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  fetchNotifications: async (req: any) => {
    const response = await fetch(`${BASE_URL}/Notifications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getAvailability: async (req: any) => {
    if (req?.request_type === 'public') {
      req.user_api = BASE_URL;
      req.api_path = '/getAvailability';
    } else {
      req.user_api = req?.data?.user_api;
      req.api_path = '/GetAvailability';
    }
    const response = await fetch(`${req?.user_api}/${ENV}/${req.api_path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.data?.user_id}`
      },
      body: JSON.stringify(req)
    });
    const resp = await response.json();
    return resp;
  },
  setAvailability: async (req: any) => {
    const response = await fetch(`${req?.availability_header_payload?.user_api}/${ENV}/UpdateAvailability`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.availability_header_payload?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getSchedule: async (req: any) => {
    if (req?.request_type === 'public') {
      req.user_api = BASE_URL;
    }
    const response = await fetch(`${req?.user_api}/${ENV}/getSchedule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getTutors: async (req: any) => {
    const response = await fetch(`${BASE_URL}/GetTutors`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req)
    });
    return response.json();
  },

  submitTutorRequest: async (req: any) => {
    const response = await fetch(`${BASE_URL}/SubmitTutorRequest`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  getJoinTeam: async (req: any) => {
    const response = await fetch(`${BASE_URL}/JoinTeam`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  gen3Rewards: async (req) => {
    // Simulate an asynchronous API call
    const response = await fetch(`${req?.user_api}/${ENV}/GetRewards`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${req?.formData?.idToken}`
      },
      body: JSON.stringify(req)
    });
    return response.json();
  },
  gen3HomePage: async () => {
    const response = await fetch(`${BASE_URL}/getHomePageData`, {
      method: 'POST',
      body: JSON.stringify({})
    });
    return response.json();
  },
  requestCourseToLocation: async (req: any) => {
    const response = await fetch(`${BASE_URL}/bring_course`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  requestCourseReschedule: async (req: any) => {
    const response = await fetch(`${req?.user_api}/${ENV}/GetRescheduleRequests`, {
      method: 'POST',
      body: JSON.stringify(req),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  },
  followTeacher: async (req: any) => {
    // TODO: fix api
    const response = await fetch(`${BASE_URL}/follow_teacher`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  },
  courseNotification: async (req: any) => {
    // TODO: fix api
    const response = await fetch(`${BASE_URL}/course_notification`, {
      method: 'POST',
      body: JSON.stringify(req)
    });
    return response.json();
  }
};
