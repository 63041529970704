import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Stack,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography
} from '@mui/material';

import { Zoom } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { sendNotificationsRequest } from '../../features/sendNotifications/actions/sendNotificationsActions';
import { AppDispatch, AppState } from '../../store/configureStore';
import DialogTitleWithClose from '../DialogTitleWithClose';

export default function SubscribeToNewsletter() {
  const dispatch: AppDispatch = useDispatch();
  const loading = useSelector((state: AppState) => state.sendNotificationsReducer.loading);

  const [nameValue, setNameValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [emailValue, setEmailValue] = useState('');

  const [nameError, setNameError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();

  const emailInputRef = useRef(null);
  const nameInputRef = useRef(null);
  const [newClasses, setNewClasses] = useState(false);
  const [newCompetitions, setNewCompetitions] = useState(false);
  const [newEvents, setNewEvents] = useState(false);
  const [camps, setCamps] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleMessage = () => {
    const params = {
      type: 'subscribe',
      phoneValue: phoneValue,
      emailValue: emailValue,
      nameValue: nameValue,
      newClasses: newClasses,
      newCompetitions: newCompetitions,
      newEvents: newEvents,
      camps: camps
    };

    dispatch(sendNotificationsRequest(params));
    setShowDialog(false);

    if (!loading) {
      enqueueSnackbar('Thank You for subscribing to our newsletter - ensure to confirm subscription of the Email', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
      setNameValue('');
      setPhoneValue('');
      setEmailValue('');
      setNewClasses(false);
      setNewCompetitions(false);
      setNewEvents(false);
      setCamps(false);
    } else {
      enqueueSnackbar('Unable to complete Subscription at this time - please try again later', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
    }
  };

  function onClick() {
    setShowDialog(true);
  }

  function onDismiss() {
    setShowDialog(false);
  }

  const submitModal = () => {
    // Validate the input
    if (!nameValue) {
      setNameError('You must specify a name.');

      return;
    }
    if (!emailValue) {
      setEmailError('You must provide an email ID to subscribe.');

      return;
    }

    handleMessage();
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={onClick} // Attach your onClick handler
      >
        Subscribe to our Newsletter
      </Button>

      {showDialog && (
        <Dialog open={true} onClose={onDismiss}>
          <DialogTitleWithClose title="Subscribe to our Newsletter" handleClose={onDismiss} />
          <DialogContent>
            <Stack spacing={3}>
              <Typography>
                Please select the options to which you want to subscribe. Do not enter any information that you do not
                wish to share publicly.
              </Typography>
              <TextField
                label="Your Name"
                value={nameValue}
                onChange={(e) => {
                  setNameValue(e.target.value);

                  // Reset error state after typing
                  setNameError(undefined);
                }}
                error={Boolean(nameError)}
                helperText={nameError}
                onBlur={() => {
                  if (!nameValue) {
                    setNameError('Please provide your name.');
                  }
                }}
                fullWidth
              />
              <TextField
                label="Subscribing Phone Number (Optional)"
                value={phoneValue}
                onChange={(e) => setPhoneValue(e.target.value)}
                fullWidth
              />
              <TextField
                label="Subscribing Email Id (Required)"
                value={emailValue}
                onChange={(e) => {
                  setEmailValue(e.target.value);
                  setEmailError(undefined);
                }}
                error={Boolean(emailError)}
                helperText={emailError}
                onBlur={() => {
                  if (!emailValue) {
                    setEmailError('Please provide an email to receive the newsletter');
                  }
                }}
                fullWidth
              />
              <Typography variant="subtitle1">Options (Required)</Typography>
              <FormControlLabel
                control={<Checkbox checked={newCompetitions} onChange={(e) => setNewCompetitions(e.target.checked)} />}
                label="New Competitions"
              />
              <FormControlLabel
                control={<Checkbox checked={newClasses} onChange={(e) => setNewClasses(e.target.checked)} />}
                label="New Classes"
              />
              <FormControlLabel
                control={<Checkbox checked={newEvents} onChange={(e) => setNewEvents(e.target.checked)} />}
                label="Events"
              />
              <FormControlLabel
                control={<Checkbox checked={camps} onChange={(e) => setCamps(e.target.checked)} />}
                label="Camps"
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Box sx={{ float: 'right' }}>
              <Stack direction="row" spacing={2}>
                <Button onClick={onDismiss}>Cancel</Button>
                <Button variant="contained" onClick={submitModal} data-testid="submit">
                  Subscribe
                </Button>
              </Stack>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
