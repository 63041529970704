import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import wait from '../../Utils/wait';
import numeral from 'numeral';
import {
  styled,
  Grid,
  Dialog,
  DialogTitle,
  Chip,
  DialogContent,
  Box,
  Zoom,
  Typography,
  TextField,
  CircularProgress,
  Avatar,
  Autocomplete,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Tooltip,
  IconButton,
  lighten,
  useMediaQuery,
  TableFooter
} from '@mui/material';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useSnackbar } from 'notistack';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { PureLightTheme } from '../../defaultTheme/schemes/PureLightTheme';
import DialogTitleWithClose from '../../Components/DialogTitleWithClose';
import { studentsRequest } from '../../features/getStudents/actions/getStudentsActions';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../../store/configureStore';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Gen3Logo from '../../Assets/Gen3Logo.svg';
import { createInvoiceRequest } from '../../features/Invoices/actions/invoiceActions';
import { GetImageURL } from '../../Components/RenderImage';

const IconButtonError = styled(IconButton)(
  ({ theme }) => `
     background: ${theme.colors.error.lighter};
     color: ${theme.colors.error.main};
     padding: ${theme.spacing(0.5)};

     &:hover {
      background: ${lighten(theme.colors.error.lighter, 0.4)};
     }
`
);

interface Item {
  id: number;
  name: string;
  quantity: number;
  price: number;
  currency: string;
}

const generateRandomString = (length: number, type: 'alpha' | 'numeric'): string => {
  const alpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numeric = '0123456789';
  const chars = type === 'alpha' ? alpha : numeric;
  return Array.from(
    { length },
    () => chars[Math.floor(Math.random() * chars.length)]
  ).join('');
};

const generateInvoiceNumber = (teacherId: string, studentId?: string): string => {
  const julianDate = format(new Date(), 'yyDDD'); // Gets current year (2 digits) and day of year
  const teacherChar = teacherId.charAt(0);
  const studentChar = studentId ? studentId.charAt(0) : 'X';
  const randomAlpha = generateRandomString(3, 'alpha');
  const randomNum = generateRandomString(3, 'numeric');

  return `${julianDate}${teacherChar}${studentChar}${randomAlpha}${randomNum}`;
};

function PageHeader() {
  const { t }: { t: any } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = PureLightTheme;
  const idToken: any = useSelector((state: AppState) => state.login.user.idToken);
  const user_api: any = useSelector((state: AppState) => state.login.account.userAPI);
  const students = useSelector((state: AppState) => state.studentsReducer.data);
  const [members, setMembers]: any[] = useState([]);
  const user_id = localStorage.getItem('userId');

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch: AppDispatch = useDispatch();

  const getStudentList = () => {
    const student_request_payload = {
      user_api: user_api,
      user_id: user_id,
      user_type: localStorage.getItem('primaryGroup')
    };
    console.log('loggedIn');
    console.log(student_request_payload);
    dispatch(studentsRequest(student_request_payload));
  };

  const itemsList: Item[] = [];

  useEffect(() => {
    getStudentList();
  }, []);

  useEffect(() => {
    if (students) {
      const newMembers = students.map((student: any) => ({
        name: student.name,
        avatar: student.profilePictureUrl,
        id: student.id,
        primaryEmail: student.email_address.primary,
        secondaryEmail: student.email_address.secondary,
      }));
      setMembers(newMembers);
    }
  }, [students]);

  const today = new Date();
  const [dueDate, setDueDate] = useState<Date>(new Date(today.setDate(today.getDate() + 5)));

  const [items, setItems] = useState<Item[]>(itemsList);

  const [selectedStudent, setSelectedStudent] = useState<any>(null);

  const [previewOpen, setPreviewOpen] = useState(false);

  const [invoiceNumber, setInvoiceNumber] = useState('');

  const [additionalInformation, setAdditionalInformation] = useState('');

  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleCreateInvoiceOpen = () => {
    setOpen(true);
  };

  const handleCreateInvoiceClose = () => {
    setAdditionalInformation('');
    setInvoiceNumber('');
    setDueDate(new Date(today.setDate(today.getDate() + 5)));
    setItems([]);
    setOpen(false);
  };

  const handleCreateInvoiceSuccess = () => {
    enqueueSnackbar(t('A new invoice has been created successfully'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    setOpen(false);
  };

  const handleDeleteItem = (itemId: number) => {
    setItems(items.filter(item => item.id !== itemId));
  };

  const handleAddItem = () => {
    const newItem: Item = {
      id: items.length + 1,
      name: '',
      quantity: 1,
      price: 0,
      currency: '$'
    };
    setItems([...items, newItem]);
  };

  const StudentAvatarImageUrl = ({ imageName }) => {
    const imageUrl: any = GetImageURL({
      object_name: imageName,
      presigned: false,
      folder: 'public/profile'
    });

    return (
      <Avatar
        sx={{
          mr: 1
        }}
        src={imageUrl}
      />
    );
  };

  const handlePreviewInvoice = async () => {
    const input = document.getElementById('invoice-preview');
    if (input) {
      const canvas = await html2canvas(input);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
      pdf.save('invoice-preview.pdf');
    }
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            {t('Invoices')}
          </Typography>
          <Typography variant="subtitle2">{t('All recent invoices can be found below')}</Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{
              mt: { xs: 2, sm: 0 }
            }}
            onClick={handleCreateInvoiceOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {t('Add new invoice')}
          </Button>
        </Grid>
      </Grid>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleCreateInvoiceClose}>
        <DialogTitleWithClose title={t('Create invoice')} handleClose={handleCreateInvoiceClose} />
        <Formik
          initialValues={{
            number: generateInvoiceNumber(user_id || ''),
            dueDate: dueDate,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            number: Yup.string().max(255).required(t('The invoice number field is required')),
            dueDate: Yup.date().required(t('Due date is required'))
          })}
          onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
            try {
              const payload = {
                action: 'createInvoice',
                idToken: idToken,
                user_api: user_api,
                user_id: user_id,
                invoiceNumber: values.number,
                dueDate: format(dueDate, 'yyyy-MM-dd'),
                invoiceDate: format(new Date(), 'yyyy-MM-dd'),
                recipient: selectedStudent,
                items: items,
                total: items.reduce((sum, item) => sum + (item.price * item.quantity), 0),
                status: 'Approved',
                additionalInformation: additionalInformation
              };

              console.log('Invoice Submission Payload:', payload);
              dispatch(createInvoiceRequest(payload));

              await wait(1000);
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              handleCreateInvoiceSuccess();
            } catch (err: any) {
              console.error('Invoice Creation Error:', err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent
                  dividers
                  sx={{
                    p: 3
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Box pb={1}>
                        <b>{t('Invoice Number')}:</b>
                      </Box>
                      <TextField
                        disabled
                        error={Boolean(touched.number && errors.number)}
                        fullWidth
                        helperText={(touched.number && errors.number) ? String(errors.number) : undefined}
                        name="number"
                        value={values.number}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box pb={1}>
                        <b>{t('Recipient')}:</b>
                      </Box>
                      <Autocomplete
                        sx={{
                          m: 0
                        }}
                        getOptionLabel={(option: any) => option.name}
                        options={members}
                        renderOption={(props, option) => (
                          <li {...props}>
                            <StudentAvatarImageUrl imageName={option.avatar} />
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{
                              shrink: true
                            }}
                            placeholder={t('Select invoice recipient...')}
                          />
                        )}
                        onChange={(event, newValue: any) => {
                          setSelectedStudent(newValue);
                          const newInvoiceNumber = generateInvoiceNumber(user_id || '', newValue?.name);
                          setInvoiceNumber(newInvoiceNumber);
                          handleChange({
                            target: {
                              name: 'number',
                              value: newInvoiceNumber
                            }
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box pb={1}>
                        <b>{t('Invoice Date')}:</b>
                      </Box>
                      <TextField
                        fullWidth
                        disabled
                        value={format(new Date(), 'dd/MM/yyyy')}
                        variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          style: { textAlign: 'right' }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box pb={1}>
                        <b>{t('Due Date')}:</b>
                      </Box>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={format(dueDate, 'dd/MM/yyyy')}
                        onFocus={() => setCalendarOpen(true)}
                        onBlur={() => setCalendarOpen(false)}
                      />
                      {calendarOpen && (
                        <ReactCalendar
                          value={dueDate}
                          onChange={(date: Date) => {
                            setDueDate(date);
                            handleChange({
                              target: {
                                name: 'dueDate',
                                value: date
                              }
                            });
                            setCalendarOpen(false);
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('Item')}</TableCell>
                        <TableCell>{t('Qty')}</TableCell>
                        <TableCell>{t('Price')}</TableCell>
                        <TableCell>{t('Total')}</TableCell>
                        <TableCell align="right">{t('Actions')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items.map((item: Item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <TextField
                              fullWidth
                              value={item.name}
                              onChange={(e) => {
                                const updatedItems = items.map(i =>
                                  i.id === item.id ? { ...i, name: e.target.value } : i
                                );
                                setItems(updatedItems);
                              }}
                              placeholder={t('Enter item name...')}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={item.quantity}
                              onChange={(e) => {
                                const updatedItems = items.map(i =>
                                  i.id === item.id ? { ...i, quantity: Number(e.target.value) } : i
                                );
                                setItems(updatedItems);
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="number"
                              value={item.price}
                              onChange={(e) => {
                                const updatedItems = items.map(i =>
                                  i.id === item.id ? { ...i, price: Number(e.target.value) } : i
                                );
                                setItems(updatedItems);
                              }}
                            />
                          </TableCell>
                          <TableCell>{numeral(item.quantity * item.price).format(`${item.currency}0,0.00`)}</TableCell>
                          <TableCell align="right">
                            <Tooltip arrow title={t('Delete')}>
                              <IconButtonError onClick={() => handleDeleteItem(item.id)}>
                                <DeleteTwoToneIcon fontSize="small" />
                              </IconButtonError>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={0}>
                          <Button
                            startIcon={<AddTwoToneIcon />}
                            variant="outlined"
                            onClick={handleAddItem}
                          >
                            {t('Add item')}
                          </Button>
                        </TableCell>
                        <TableCell colSpan={4} align="right">
                          <Typography gutterBottom variant="caption" color="text.secondary" fontWeight="bold">
                            {t('Total')}:
                          </Typography>
                          <Typography variant="h3" fontWeight="bold">
                            {numeral(items.reduce((sum, item) => sum + (item.price * item.quantity), 0)).format(`$0,0.00`)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
                <Box px={3} pt={3}>
                  <TextField
                    label={t('Additional information')}
                    multiline
                    value={additionalInformation}
                    onChange={(e) => setAdditionalInformation(e.target.value)}
                    placeholder={t('Write here any additional information you might have...')}
                    fullWidth
                    minRows={3}
                    maxRows={8}
                  />
                </Box>
                <Box
                  sx={{
                    display: { xs: 'block', sm: 'flex' },
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 3
                  }}
                >
                  <Box>
                    <Button fullWidth={mobile} variant="outlined" onClick={handlePreviewInvoice}>
                      {t('Preview invoice')}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      fullWidth={mobile}
                      type="submit"
                      startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                      disabled={Boolean(errors.submit) || isSubmitting}
                      variant="contained"
                      size="large"
                    >
                      {t('Create invoice')}
                    </Button>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Formik>
      </Dialog>
      <Dialog open={previewOpen} onClose={handlePreviewClose} fullWidth maxWidth="md">
        <DialogTitleWithClose title={t('Invoice Preview')} handleClose={handlePreviewClose} />
        <DialogContent>
          <Box id="invoice-preview">
            <img src={Gen3Logo} alt="Gen3 Logo" />
            <Typography variant="h3">
              {selectedStudent?.name.toUpperCase()} Invoice
            </Typography>
            <Typography variant="h4" sx={{ mb: 2 }}>
              Month Of {format(new Date(), 'MMMM yyyy')}
            </Typography>
            <Typography>{t('Invoice Number')}: {invoiceNumber}</Typography>
            <Typography>{t('Due Date')}: {format(dueDate, 'dd/MM/yyyy')}</Typography>
            <Typography>{t('Recipient')}: {selectedStudent?.name}</Typography>
            <Typography>{t('Invoice Date')}: {format(new Date(), 'dd/MM/yyyy')}</Typography>
            <Typography>{t('Additional information')}: {additionalInformation}</Typography>
            <Typography>{t('Items')}: </Typography>
            {items.map((item: Item) => (
              <Typography key={item.id}>{item.name} - {item.quantity} x {item.price} = {item.quantity * item.price}</Typography>
            ))}
            <Typography>{t('Total')}: {numeral(items.reduce((sum, item) => sum + (item.price * item.quantity), 0)).format(`$0,0.00`)}</Typography>


          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default PageHeader;
