// features/login/actions/LoginActionTypes.ts
export enum LoginActionTypes {
  LOGIN_ACCOUNT = 'login/LOGIN_ACCOUNT',
  LOGIN_ACCOUNT_SUCCESS = 'login/LOGIN_ACCOUNT_SUCCESS',
  LOGIN_ACCOUNT_ERROR = 'login/LOGIN_ACCOUNT_ERROR',
  LOGIN_USER = 'login/LOGIN_USER',
  LOGIN_USER_SUCCESS = 'login/LOGIN_USER_SUCCESS',
  LOGIN_USER_ERROR = 'login/LOGIN_USER_ERROR',
  LOGIN_APPLICANT = 'login/LOGIN_APPLICANT',
  LOGIN_APPLICANT_SUCCESS = 'login/LOGIN_APPLICANT_SUCCESS',
  LOGIN_APPLICANT_ERROR = 'login/LOGIN_APPLICANT_ERROR'
}

export interface LoginAction {
  type: LoginActionTypes;
  payload?: any;
}
