export interface LoginState {
  user: string | null;
  account: {
    cognito_user_pool_id: string | null;
    client_id: string | null;
    identity_pool_id: string | null;
    username: string | null;
  } | null;
  loading: boolean;
  error: string | null;
  loggedIn: boolean;
  newPasswordRequired: boolean;
  loginError: boolean;
  errorMessage: string;
  applicant: {
    email: string | null;
    password: string | null;
  } | null;
  applicantLoading: boolean;
  applicantError: string | null;
}

export const initialState: LoginState = {
  user: null,
  account: {
    cognito_user_pool_id: null,
    client_id: null,
    identity_pool_id: null,
    username: null,
  },
  loading: false,
  error: null,
  loggedIn: false,
  newPasswordRequired: false,
  loginError: false,
  errorMessage: "",
  applicant: null,
  applicantLoading: false,
  applicantError: null,
};
